import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookF, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa";
import './Footer.css';
import data from '../../data/AudioBank.json';

const Footer = ({ onCalendarIconClick, currentMonth }) => {
  const [popuptexts, setPopuptexts] = useState({});
  /*const [showPopupInfo, setShowPopupInfo] = useState(false);
  const [showPopupShut, setShowPopupShut] = useState(false);
  const [showPopupStay, setShowPopupStay] = useState(false);*/

  useEffect(() => {
    setPopuptexts(data[currentMonth] || {});
  }, [currentMonth]);

  /*const togglePopupInfo = () => {
    setShowPopupInfo(!showPopupInfo);
    setShowPopupShut(false);
    setShowPopupStay(false);
  };

  const togglePopupShut = () => {
    setShowPopupShut(!showPopupShut);
    setShowPopupStay(false);
    setShowPopupInfo(false);
  };

  const togglePopupStay = () => {
    setShowPopupStay(!showPopupStay);
    setShowPopupShut(false);
    setShowPopupInfo(false);
  };*/

  const shareOnFacebook = () => {
    const url = encodeURIComponent(window.location.href);
    const text = encodeURIComponent('Check out this awesome website!');
    window.open(`https://www.facebook.com/sharer/sharer.php?u=${url}&text=${text}`, '_blank');
  };
  
  /*const shareOnInstagram = () => {
    const url = 'https://www.instagram.com/fortude.co/';
    window.open(url, '_blank');
  };

  const shareOnTwitter = () => {
    const url = encodeURIComponent(window.location.href);
    const text = encodeURIComponent('Check out this awesome website!');
    window.open(`https://twitter.com/intent/tweet?url=${url}&text=${text}`, '_blank');
  };*/
  
  
  const shareOnLinkedin = () => {
    const url = encodeURIComponent(window.location.href);
    const text = encodeURIComponent('Check out this awesome website!');
    window.open(`https://www.linkedin.com/sharing/share-offsite/?url=${url}&text=${text}`, '_blank');
  };

  return (
    <div className="footer">
      <div className='footer-left'>
        <div className='row popup' /*onClick={togglePopupInfo}*/>
          <img className='popicon' src="/assets/icons/info.png" alt="infomation" />
          <p className='popicon-title'>information</p>
          {/*{showPopupInfo && (*/}
            <div className='popuptext info'>
              {popuptexts.info}
            </div>
          {/*)}*/}
        </div>
        <div className='row popup' /*onClick={togglePopupShut}*/>
          <img className='popicon' src="/assets/icons/shutterbugs.png" alt="shutterbugs" />
          <p className='popicon-title'>Shutterbugs</p>
          {/*{showPopupShut && (*/}
            <div className='popuptext shutter'>
              {popuptexts.shutterbugs}
            </div>
          {/*)}*/}
        </div>

        <div className='row popup' onClick={onCalendarIconClick}>
          <img className='popicon' src="/assets/icons/calender.png" alt="calendar" />
          <p className='popicon-title'>Calendar</p>
        </div>

        <div className='row popup slider' /*onClick={togglePopupStay}*/>
          <img className='popicon' src="/assets/icons/staywell.png" alt="staywell" />
          <p className='popicon-title slider'>Stay Well</p>
          {/*{showPopupStay && (*/}
            <div className='popuptext stay slider'>
              {popuptexts.staywell}
            </div>
          {/*)}*/}
        </div>
      </div>
      {/*<div className='footer-right'>
        <div className='social-media'>
          <a href="https://www.facebook.com/fortudite" target='_blank' ><FaFacebookF /></a>
          <a href="https://www.instagram.com/fortude.co/" target='_blank' ><FaInstagram /></a>
          <a href="https://www.linkedin.com/company/13305991/admin/feed/posts/" target='_blank' ><FaLinkedin /></a>
        </div>
      </div>*/}
      <div className='footer-right'>
        <div className='social-media'>
          {/*<p onClick={shareOnInstagram}><FaInstagram /></p>*/}
          <p onClick={shareOnFacebook}><FaFacebookF /></p>
          {/*<p onClick={shareOnTwitter}><FaTwitter /></p>*/}
          <p onClick={shareOnLinkedin}><FaLinkedin /></p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
